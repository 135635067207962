import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="ranger-spells"></a><h3>RANGER SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="first"></a><a id="fisrt-level-ranger-spells"></a><h5>1ST-LEVEL RANGER SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#alarm">Alarm</a>:</td>
          <td> Wards an area for 2 hours/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-messenger">Animal
Messenger</a>:</td>
          <td> Sends a Tiny animal to a specific place.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-animals">Calm Animals</a>:</td>
          <td> Calms (2d4 + level) HD of animals.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-animal">Charm Animal</a>:</td>
          <td> Makes one animal your friend.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delay-poison">Delay Poison</a>:</td>
          <td> Stops poison from harming subject for 1 hour/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-animals-or-plants">Detect
Animals or Plants</a>:</td>
          <td> Detects kinds of animals or plants.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-poison">Detect Poison</a>:</td>
          <td> Detects poison in one creature or object.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-snares-and-pits">Detect
Snares and Pits</a>:</td>
          <td> Reveals natural or primitive traps.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#entangle">Entangle</a>:</td>
          <td> Plants entangle everyone in 40-ft.-radius circle.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hide-from-animals">Hide from
Animals</a>:</td>
          <td> Animals can&rsquo;t perceive one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#jump">Jump</a>:</td>
          <td> Subject gets bonus on Jump checks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#longstrider">Longstrider</a>:</td>
          <td> Increases your speed.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-fang">Magic Fang</a>:</td>
          <td> One natural weapon of subject creature gets +1 on
attack and damage rolls.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#pass-without-trace">Pass
without Trace</a>:</td>
          <td> One subject/level leaves no tracks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>:</td>
          <td> Ignores 10 (or more) points of damage/attack from
specified energy type.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-animals">Speak with
Animals</a>:</td>
          <td> You can communicate with animals.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-i">Summon
Nature&rsquo;s Ally I</a>:</td>
          <td> Calls animal to fight for you.</td>
        </tr>
      </tbody>
    </table>
    <a id="second"></a><a id="second-level-ranger-spells"></a><h5>2ND-LEVEL RANGER SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#barkskin">Barkskin</a>:</td>
          <td> Grants +2 (or higher) enhancement to natural armor.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bears-endurance">Bear&rsquo;s
Endurance</a>:</td>
          <td> Subject gains +4 to Con for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cats-grace">Cat&rsquo;s Grace</a>:</td>
          <td> Subject gains +4 to Dex for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-animal">Hold Animal</a>:</td>
          <td> Paralyzes one animal for 1 round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#owls-wisdom">Owl&rsquo;s Wisdom</a>:</td>
          <td> Subject gains +4 to Wis for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#snare">Snare</a>:</td>
          <td> Creates a magic booby trap.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-plants">Speak with
Plants</a>:</td>
          <td> You can talk to normal plants and plant creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spike-growth">Spike Growth</a>:</td>
          <td> Creatures in area take 1d4 damage, may be slowed.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-ii">Summon
Nature&rsquo;s Ally II</a>:</td>
          <td> Calls animal to fight for you.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-wall">Wind
Wall</a>:</td>
          <td className="last-row"> Deflects arrows, smaller creatures,
and gases.</td>
        </tr>
      </tbody>
    </table>
    <a id="third"></a><a id="third-level-ranger-spells"></a><h5>3RD-LEVEL RANGER SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#command-plants">Command Plants</a>:</td>
          <td> Sway the actions of one or more plant creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#darkvision">Darkvision</a>:</td>
          <td> See 60 ft. in total darkness.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#diminish-plants">Diminish
Plants</a>:</td>
          <td> Reduces size or blights growth of normal plants.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-fang">Magic
Fang, Greater</a>:</td>
          <td> One natural weapon of subject creature gets +1/four
caster levels on attack and damage rolls (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize
Poison</a>:</td>
          <td> Immunizes subject against poison, detoxifies venom in
or on subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#plant-growth">Plant Growth</a>:</td>
          <td> Grows vegetation, improves crops.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reduce-animal">Reduce Animal</a>:</td>
          <td> Shrinks one willing animal.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-disease">Remove Disease</a>:</td>
          <td> Cures all diseases affecting subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-vermin">Repel Vermin</a>:</td>
          <td> Insects, spiders, and other vermin stay 10 ft. away.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-iii">Summon
Nature&rsquo;s Ally III</a>:</td>
          <td> Calls animal to fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tree-shape">Tree Shape</a>:</td>
          <td> You look exactly like a tree for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-walk">Water Walk</a>:</td>
          <td> Subject treads on water as if solid.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth"></a><a id="fourth-level-ranger-spells"></a><h5>4TH-LEVEL RANGER SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-growth">Animal Growth</a>:</td>
          <td> One animal/two levels doubles in size.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#commune-with-nature">Commune with
Nature</a>:</td>
          <td> Learn about terrain for 1 mile/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure Serious
Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom
of Movement</a>:</td>
          <td> Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nondetection">Nondetection</a><sup>
M</sup>:</td>
          <td> Hides subject from divination, scrying.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-iv">Summon
Nature&rsquo;s Ally IV</a>:</td>
          <td> Calls animal to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tree-stride">Tree Stride</a>:</td>
          <td> Step from one tree to another far away.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      